<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="subscriptions"
      :options.sync="pagination"
      :server-items-length="totalSubscriptions"
      :loading="loading"
      :footer-props="{
        'showFirstLastPage':true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
      class="elevation-1"
    >

      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>

          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  retrieveSubscriptions();
                "
                >mdi-magnify
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <v-avatar color="grey" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  clearSearch();
                "
                >mdi-close
              </v-icon>
            </v-avatar>
          </td>
        </tr>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import SubscriptionApiService from "@/core/services/api.service.subscription";

export default {
  name: "subscriptions",
  data() {
    return {
      totalSubscriptions: 0,
      subscriptions: [],
      loading: true,
      pagination: {},
      headers: [
        { text: "Id", align: "start", sortable: true, value: "id" },
        { text: "Shop", value: "shop" },
        { text: "Created", value: "created" },
        { text: "Active", value: "active" },
        { text: "Subscription Id", value: "subscriptionId" },
        { text: "Subscription Name", value: "subscriptionName" },
        { text: "Interval", value: "subscriptionTimeIntervalType" },
        { text: "AutoRenewal", value: "autoRenewal" },
        { text: "Price", value: "price" },
        { text: "Last Payment Date", value: "lastPaymentDate" },
        { text: "Invoice Status", value: "invoiceStatus" },
        { text: "Invoice Url", value: "invoiceUrl" },
        { text: "", value: "search", width: "5%" },
        { text: "", value: "clear", width: "5%" }
      ],
      errors: []
    };
  },
  watch: {
    pagination: {
      async handler() {
        this.retrieveSubscriptions();
      },
      deep: true
    }
  },
  async created() {
    this.loading = true;
    this.$log.debug("await init sub service - start");
    await SubscriptionApiService.init();
    this.$log.debug("await init sub service - end");
    this.retrieveSubscriptions();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Subscriptions" }]);
  },
  components: {
    ConfirmDialog
  },
  methods: {
    parseDate(timestamp) {
      return new Date(timestamp.seconds * 1000);
    },
    getRequestParams() {
      let params = {};

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;

      return params;
    },
    async retrieveSubscriptions () {

      this.$log.debug("await init sub service - start");
      await SubscriptionApiService.init();
      this.$log.debug("await init sub service - end");
      const params = this.getRequestParams();

      this.$log.debug("params: ", params);
      return new Promise(resolve => {
        this.loading = true;
        SubscriptionApiService.query("/GetAllFiscalizations", {
          params
        }).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("Subscriptions: ", response.data.content);
          this.subscriptions = response.data.content;
          this.totalSubscriptions = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title =
            this.totalSubscriptions != null
              ? this.totalSubscriptions
              : 0;
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Subscriptions: " + title }
          ]);
          resolve();
        });
      });
    }
  }
};
</script>

<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}
.v-icon.v-icon {
  font-size: 24px !important;
}
tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
</style>
